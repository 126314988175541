import NodeCache from "node-cache";

import { API_BASE, CONTENT_API_URL } from "lib/constants";
import type { FooterData, NavData } from "lib/types/common";
import fetcher from "../fetcher";

const MENU_ITEMS_API_URL = API_BASE + "/structure/menus";
const LATEST_ARTICLES_API_URL = CONTENT_API_URL + "/articles/?page=1";
const fetcherCache = new NodeCache({ stdTTL: 300, checkperiod: 120 });

export const fetchMenuItems = async (): Promise<{
  nav: NavData;
  footer: FooterData;
}> => {
  let items: { nav: NavData; footer: FooterData } | undefined =
    fetcherCache.get("commonFetchMenuItems");
  if (items === undefined || !(items?.nav?.topPrimary?.length > 0)) {
    const menuItems = await fetcher(MENU_ITEMS_API_URL);

    items = {
      nav: {
        topPrimary: menuItems.topPrimary,
        sidePrimary: menuItems.sidePrimary,
        secondary: menuItems.secondary,
        sideTertiary: [
          { title: "About", url: "/about" },
          { title: "Press kit", url: "/press-kit" },
          { title: "Terms of use", url: "/terms-of-use" },
          { title: "Privacy policy", url: "/privacy-policy" },
          { title: "Contact us", url: "mailto:hello@sifted.eu" },
        ],
      },
      footer: {
        about_us: menuItems.footer.about_us,
        browse: menuItems.footer.browse,
        more: menuItems.footer.more,
        mobile: menuItems.footer.mobile,
      },
    };
    fetcherCache.set("commonFetchMenuItems", items);
  }
  return items;
};

export const fetchLatestArticles = async ({
  limit = 4,
}: { limit?: number } = {}) => {
  const data = await fetcher(LATEST_ARTICLES_API_URL);
  return data.articles
    .map((article) => ({
      title: article.title,
      image: article.image["base"] ?? null,
      url: article.url,
      author: article.author.name,
      sector: article.sector,
      contentSubscriptionLevel: article.contentSubscriptionLevel,
    }))
    .slice(0, limit);
};

export * from "./sitewideOption";
