import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import ReactMarkdown from "markdown-to-jsx";
import Balancer from "react-wrap-balancer";

import { useUserData } from "lib/store/user";
import { PaywallWindow } from "lib/types/paywalls";
import { fetchPaywallById } from "lib/fetchers/common";
import {
  trackGTMEvent,
  updatePaywallCounter,
  UpdatePaywallCounterProps,
} from "lib/utils";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

import Button from "components/buttonNew/button";
import Modal from "components/modalNew/modal";
import TextButton from "components/textButton/textButton";
import { trackHubspotEvent } from "./hubspotEvents";

type PaywallContentProps = {
  previewData?: {
    paywallId?: string;
  };
  preview: boolean;
  paywallType: UpdatePaywallCounterProps["type"];
  hubspotEventType?: string;
};

const PaywallContent = ({
  previewData,
  preview,
  paywallType,
  hubspotEventType,
}: PaywallContentProps) => {
  const { track, userId } = useMixpanelTracking();
  const { readerSubscriptionLevel } = useUserData();
  const [openId, setOpenId] = useState<string | null>(null);
  const [paywall, setPaywall] = useState<PaywallWindow | null>();

  const fetchPaywall = useCallback(
    async (id: string, preview: boolean) => {
      if (id) {
        const data = await fetchPaywallById({ id: id, preview });

        if (data && !preview) {
          trackHubspotEvent("Open", hubspotEventType || data.name);

          await track("Barrier show", {
            "Barrier name": data.name,
            "Barrier id": data.id,
          });

          trackGTMEvent({
            event: "Barrier show",
            barrierType: "paywall_window",
            barrierName: data.name,
            barrierId: data.id,
            paywallEventAction: "Barrier show",
          });
        }

        setPaywall(data);
      }
    },
    [track, hubspotEventType]
  );

  // This event is fired from Piano when the paywall modal should be shown
  useEffect(() => {
    async function openModal() {
      // @ts-ignore
      const paywallId = window.paywallId;
      setOpenId(paywallId);

      await updatePaywallCounter({
        type: paywallType,
        timestamp: (new Date().getTime() / 1000).toFixed(0),
      });

      await fetchPaywall(paywallId, preview);
    }

    window.addEventListener("displayPaywall", openModal);

    return () => {
      window.removeEventListener("displayPaywall", openModal);
    };
    // eslint-disable-next-line
  }, [userId]);

  // Opens paywall on load for preview mode
  useEffect(() => {
    if (preview && previewData?.paywallId) {
      const paywall = previewData?.paywallId;

      setOpenId(paywall);
      fetchPaywall(paywall, preview);
    }
    // eslint-disable-next-line
  }, [preview, previewData]);

  const onOpenChange = async (paywall: PaywallWindow) => {
    await track("Barrier dismiss", {
      "Barrier name": paywall.name,
      "Barrier id": paywall.id,
      "Dismiss type": "Escape, Close, Overlay",
    });

    trackGTMEvent({
      event: "Barrier dismiss",
      barrierType: "paywall_window",
      barrierName: paywall.name,
      barrierId: paywall.id,
      paywallEventAction: "Barrier dismiss",
    });

    trackHubspotEvent("Dismiss", hubspotEventType || paywall.name);

    setOpenId(null);
  };

  const onLoginButtonClicked = async (paywall: PaywallWindow) => {
    const params = new URLSearchParams({
      redirectUrl: window.location.href,
    });
    const loginUrl = `/members/login?${params}`;

    await track("Barrier login button click", {
      "Barrier name": paywall.name,
      "Barrier id": paywall.id,
      "Click link": loginUrl,
      "Click text": paywall.loginLabel,
    });

    trackGTMEvent({
      event: "Barrier login button click",
      barrierType: "paywall_window",
      barrierName: paywall.name,
      barrierId: paywall.id,
      clickLink: loginUrl,
      clickText: paywall.loginLabel,
      paywallEventAction: "Barrier login button click",
    });

    trackHubspotEvent("LogIn", hubspotEventType || paywall.name);

    if (window.pianoTrackingId && window.Cookiebot?.consent?.statistics) {
      window.tp.log.logMicroConversion(window.pianoTrackingId, "login-button", {
        button: "Paywall Login Button",
        label: paywall.loginLabel,
        url: loginUrl,
        ...("pianoContext" in window && typeof window.pianoContext === "object"
          ? window.pianoContext
          : {}),
      });
    }

    window.location.assign(loginUrl);
  };

  const onPrimaryButtonClick = async (paywall: PaywallWindow) => {
    trackGTMEvent({
      event: "Barrier primary button click",
      barrierType: "paywall_window",
      barrierName: paywall.name,
      barrierId: paywall.id,
      clickLink: paywall.primaryButtonUrl,
      clickText: paywall.primaryButtonLabel,
      paywallEventAction: "Barrier primary button click",
    });

    trackHubspotEvent("Primary", hubspotEventType || paywall.name);

    // these events can't be tested in dato preview as they require context from piano
    if (window.pianoTrackingId && window.Cookiebot?.consent?.statistics) {
      window.tp.log.logMicroConversion(
        window.pianoTrackingId,
        "main-cta-button",
        {
          button: "Paywall Primary Button",
          label: paywall.primaryButtonLabel,
          url: paywall.primaryButtonUrl,
          ...("pianoContext" in window &&
          typeof window.pianoContext === "object"
            ? window.pianoContext
            : {}),
        }
      );
    }

    await track("Barrier primary button click", {
      "Barrier name": paywall.name,
      "Barrier id": paywall.id,
      "Click link": paywall.primaryButtonUrl,
      "Click text": paywall.primaryButtonLabel,
    });

    if (paywall.primaryButtonUrl.includes("/members/login")) {
      const params = new URLSearchParams({
        redirectUrl: window.location.href,
      });
      const loginUrl = `${paywall.primaryButtonUrl}?${params}`;
      window.location.assign(loginUrl);
    } else {
      window.location.assign(paywall.primaryButtonUrl);
    }
  };

  const onDismissButtonClick = async (paywall: PaywallWindow) => {
    if (window.pianoTrackingId && window.Cookiebot?.consent?.statistics) {
      window.tp.log.logMicroConversion(
        window.pianoTrackingId,
        "dismiss-button",
        {
          button: "Paywall Dismiss Button",
          label: paywall.dismissButtonLabel,
          url: paywall.dismissButtonUrl,
          ...("pianoContext" in window &&
          typeof window.pianoContext === "object"
            ? window.pianoContext
            : {}),
        }
      );
    }

    trackHubspotEvent("Dismiss", hubspotEventType || paywall.name);

    await track("Barrier dismiss", {
      "Barrier name": paywall.name,
      "Barrier id": paywall.id,
      "Dismiss type": "Dismiss button click",
      "Click link": paywall.dismissButtonUrl,
      "Click text": paywall.dismissButtonLabel,
    });

    trackGTMEvent({
      event: "Barrier dismiss",
      barrierType: "paywall_window",
      barrierName: paywall.name,
      barrierId: paywall.id,
      clickLink: paywall.dismissButtonUrl,
      clickText: paywall.dismissButtonLabel,
      paywallEventAction: "Barrier dismiss button click",
    });

    if (paywall.dismissButtonUrl) {
      if (paywall.dismissButtonUrl.includes("/members/login")) {
        const params = new URLSearchParams({
          redirectUrl: window.location.href,
        });
        const loginUrl = `${paywall.dismissButtonUrl}?${params}`;
        window.location.assign(loginUrl);
      } else {
        window.location.assign(paywall.dismissButtonUrl);
      }
    } else {
      await onOpenChange(paywall);
    }
  };

  const onAdditionalButtonClick = async (paywall: PaywallWindow) => {
    trackGTMEvent({
      event: "Barrier additional button click",
      barrierType: "paywall_window",
      barrierName: paywall.name,
      barrierId: paywall.id,
      clickLink: paywall.additionalButtonUrl,
      clickText: paywall.additionalButtonLabel,
      paywallEventAction: "Barrier additional button click",
    });

    trackHubspotEvent("Additional", hubspotEventType || paywall.name);

    // these events can't be tested in dato preview as they require context from piano
    if (window.pianoTrackingId && window.Cookiebot?.consent?.statistics) {
      window.tp.log.logMicroConversion(
        window.pianoTrackingId,
        "additional-cta-button",
        {
          button: "Paywall Additional Button",
          label: paywall.additionalButtonLabel,
          url: paywall.additionalButtonUrl,
          ...("pianoContext" in window &&
          typeof window.pianoContext === "object"
            ? window.pianoContext
            : {}),
        }
      );
    }

    await track("Barrier additional button click", {
      "Barrier name": paywall.name,
      "Barrier id": paywall.id,
      "Click link": paywall.additionalButtonUrl,
      "Click text": paywall.additionalButtonLabel,
    });

    if (paywall.additionalButtonUrl) {
      if (paywall.additionalButtonUrl.includes("/members/login")) {
        const params = new URLSearchParams({
          redirectUrl: window.location.href,
        });
        const loginUrl = `${paywall.additionalButtonUrl}?${params}`;
        window.location.assign(loginUrl);
      } else {
        window.location.assign(paywall.additionalButtonUrl);
      }
    }
  };

  if (!paywall) return null;

  return (
    <Modal
      positionMobile={"center"}
      closable={false}
      isOpen={Boolean(openId) && Boolean(paywall)}
      title={
        <span
          data-testid={"paywallContent__title-container"}
          className={classNames(
            "w-full pt-[14px] text-center font-recklessBold text-[26px] leading-[130%] md:px-4",
            {
              "text-pro-blue":
                paywall.theme === "pro" && paywall.titleColour === "primary",
              "text-cerise":
                paywall.theme === "cerise" && paywall.titleColour === "primary",
              "text-black-rock": paywall.titleColour === "neutral",
            }
          )}
        >
          <Balancer ratio={0.6}>{paywall.title}</Balancer>
        </span>
      }
      forceMount={false}
      innerClassNames={classNames(
        "js-fixed-exclude w-[100vw] max-w-[320px] box-content md:w-[400px] md:max-w-[400px]"
      )}
      contentClassNames={""}
      onOpenChange={async (state) => !state && (await onOpenChange(paywall))}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
        const target = e.target as HTMLDivElement | undefined;
        target?.focus();
      }}
    >
      <div
        className={
          "flex flex-col text-center text-[16px] leading-[130%] text-mono-70 md:px-4 md:pb-[14px]"
        }
      >
        <div className={"mb-5 flex flex-col items-center justify-center"}>
          {paywall.subtitle && (
            <Balancer>
              <ReactMarkdown
                options={{
                  forceBlock: true,
                  overrides: {
                    a: (props) => {
                      return (
                        <a
                          href={props.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline"
                          onClick={async (e) => {
                            if (props.href.includes("/members/login")) {
                              e.stopPropagation();
                              await onLoginButtonClicked(paywall);
                            }
                          }}
                        >
                          {props.children}
                        </a>
                      );
                    },
                  },
                }}
                className={classNames(
                  "text-[16px] leading-[130%] text-mono-70",
                  "[&_p]:m-0",
                  "[&_a]:inline [&_a]:underline [&_a]:[text-underline-position:under]",
                  "focus:[&_a]:outline-none focus-visible:[&_a]:outline-none focus-visible:[&_a]:ring",
                  {
                    "text-mono-40": paywall.theme === "cerise",
                    "[&_a]:text-pro-blue focus-visible:[&_a]:ring-pro-blue":
                      paywall.theme === "pro",
                    "[&_a]:text-cerise focus-visible:[&_a]:ring-cerise":
                      paywall.theme === "cerise",
                  }
                )}
              >
                {paywall.subtitle}
              </ReactMarkdown>
            </Balancer>
          )}
        </div>
        <div className={"flex flex-col items-center justify-center gap-3"}>
          <div
            className={classNames(
              "flex w-full flex-wrap items-center justify-center gap-[10px]"
            )}
          >
            <Button
              size={"medium"}
              colour={paywall.theme === "pro" ? "navy" : "cerise"}
              className={classNames({
                "max-sm:px-5 md:flex-grow":
                  readerSubscriptionLevel === "unregistered",
                "max-sm:px-6": readerSubscriptionLevel !== "unregistered",
              })}
              onClick={async () => await onPrimaryButtonClick(paywall)}
            >
              {paywall.primaryButtonLabel}
            </Button>
            {readerSubscriptionLevel === "unregistered" && (
              <Button
                size={"medium"}
                buttonType={"secondary"}
                colour={paywall.theme === "pro" ? "navy" : "cerise"}
                className={"max-sm:px-6"}
                onClick={async () => await onLoginButtonClicked(paywall)}
              >
                {paywall.loginLabel}
              </Button>
            )}
          </div>
          <div className={"flex flex-row gap-5"}>
            {paywall.additionalButtonLabel && (
              <TextButton
                colour={paywall.theme === "cerise" ? "cerise" : "pro-blue"}
                component={"button"}
                onClick={async () => await onAdditionalButtonClick(paywall)}
              >
                {paywall.additionalButtonLabel}
              </TextButton>
            )}
            <TextButton
              colour={"black"}
              component={"button"}
              onClick={async () => await onDismissButtonClick(paywall)}
            >
              {paywall.dismissButtonLabel}
            </TextButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaywallContent;
