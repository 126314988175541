declare global {
  interface Window {
    _hsq: any;
  }
}

interface HubspotEvent {
  name: string;
  properties: Record<string, any>;
}

export type HubspotEventType =
  | "LeaderboardsDownloadPaywall"
  | "RankingsDownloadPaywall"
  | "NewsLetterPopup";

type HubspotEventActions =
  | "Open"
  | "Close"
  | "LogIn"
  | "Primary"
  | "Dismiss"
  | "Additional";

export const HubspotEventTypeProperties: Record<
  string,
  Partial<Record<HubspotEventActions, HubspotEvent>>
> = {
  "[NEW] Regwall - Register to read more each month": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "regwall",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "signup",
      },
    },
    LogIn: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "login",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  "[NEW] Paywall | Free Account - You’ve just hit the article limit": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "free_account",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "sign_up",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  "[NEW] Paywall | Scout - Only Pro Subscribers can access Scout": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "scout",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  "[NEW] Paywall | Briefings - Only Pro Subscribers can read Briefings": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "briefings",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  "Paywall | Pro article": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "pro_article",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  "Paywall | Pro article | Sifted for Startups": {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "pro_article_sfs",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "apply_now",
      },
    },
    Additional: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "other_subscriptions",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  LeaderboardsDownloadPaywall: {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "leaderboard",
      },
    },
    Additional: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  RankingsDownloadPaywall: {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "rankings",
      },
    },
    Additional: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
    Dismiss: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "back_to_home_page",
      },
    },
  },
  NewsLetterPopup: {
    Open: {
      name: "pe20037605_paywall_popups",
      properties: {
        paywall_type: "newsletter",
      },
    },
    Primary: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "sign_up",
      },
    },
    Additional: {
      name: "pe20037605_paywall_ctas",
      properties: {
        cta_type: "find_out_more",
      },
    },
  },
};

export const trackHubspotEvent = async (
  action: HubspotEventActions,
  paywallName?: string
) => {
  if (
    !paywallName ||
    !HubspotEventTypeProperties[paywallName] ||
    !HubspotEventTypeProperties[paywallName][action]
  ) {
    return;
  }

  const event = HubspotEventTypeProperties[paywallName][action];
  if (!event) {
    return;
  }

  const { name, properties } = event;
  if (window._hsq) {
    window._hsq.push([
      "trackCustomBehavioralEvent",
      {
        name,
        properties,
      },
    ]);
  }
};
