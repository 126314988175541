import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";

import { useReserveScrollbarWidth } from "lib/hooks/useReserveScrollbarWidth";
import IconButton from "components/iconButton/iconButton";

type ModalProps = {
  isOpen: boolean;
  title?: string | React.ReactNode | null;
  overlayClassNames?: string;
  innerClassNames?: string;
  contentClassNames?: string;
  children?: React.ReactElement;
  onOpenChange?: (open: boolean) => void;
  onOpenAutoFocus?: Dialog.DialogContentTypeProps["onOpenAutoFocus"];
  forceMount?: boolean;
  /**
   * If negative, removes cross button and do not trigger closing events
   * Can be closed only from the outside
   */
  closable?: boolean;
  overlayColor?: "dark" | "light";
  positionMobile?: "bottom" | "center";
};

const Modal = ({
  isOpen,
  title,
  overlayClassNames,
  contentClassNames,
  innerClassNames,
  children,
  onOpenChange,
  onOpenAutoFocus,
  forceMount,
  closable = true,
  overlayColor = "dark",
  positionMobile = "bottom",
}: ModalProps) => {
  const [originalOverflow, setOriginalOverflow] = useState<string>("");
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOriginalOverflow(window.getComputedStyle(document.body).overflow);

    return function cleanup() {
      document.body.style.overflow = originalOverflow;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Prevent background from scrolling when sidebar is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalOverflow;
    }
    // Re-enable scrolling when component unmounts
    return function cleanup() {
      document.body.style.overflow = originalOverflow;
    };
  }, [open, originalOverflow]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useReserveScrollbarWidth({ modalOpened: open });

  const handleOpenChange = (open: boolean) => {
    if (onOpenChange) {
      if (closable) {
        onOpenChange(open);
      }
    } else {
      if (closable) {
        setOpen(open);
      }
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={(open) => handleOpenChange(open)}>
      <Dialog.Portal
        forceMount={
          forceMount !== undefined
            ? forceMount || undefined
            : isOpen || undefined
        }
      >
        {/* We can't use <Dialog.Overlay /> because react-remove-scroll causes flickering of navbar, using our scroll-block instead */}
        <div
          data-state={open ? "open" : ""}
          data-testid={"modal__overlay"}
          className={classNames(
            "fixed inset-0 z-50 data-[state=open]:animate-overlayShow",
            "transition-all duration-200",
            {
              "bg-[#000000B2]": overlayColor === "dark",
              "bg-[#E3E3E5] bg-opacity-[80%]": overlayColor === "light",
            },
            overlayClassNames
          )}
        />
        <Dialog.Content
          onOpenAutoFocus={onOpenAutoFocus}
          tabIndex={0}
          data-testid={"modal__inner"}
          className={classNames(
            "fixed z-[51] focus:outline-none",
            "max-w-[100vw] lg:max-w-[90vw]",
            "transform transition-none",
            "bottom-auto left-[50%] top-[50%] data-[state=open]:animate-contentShow",
            "translate-x-[-50%] translate-y-[-50%] data-[state=open]:translate-y-[-50%]",
            {
              "duration-200 ease-out max-md:bottom-0 max-md:top-auto max-md:translate-y-100 max-md:transition-transform max-md:data-[state=open]:translate-y-0 max-md:data-[state=open]:animate-none":
                positionMobile === "bottom",
            },
            innerClassNames
          )}
        >
          <div
            className={"bg-white px-[20px] pb-[30px] pt-[16px] lg:pb-[16px]"}
          >
            <div className={"mb-[10px] flex justify-between gap-[20px]"}>
              <div className={"w-full"}>
                {title && (
                  <Dialog.Title
                    className={
                      "flex min-h-[34px] items-center font-bold text-[18px] leading-[130%]"
                    }
                  >
                    {title}
                  </Dialog.Title>
                )}
              </div>
              {closable && (
                <div className={"flex"}>
                  <IconButton
                    aria-label={"Close modal"}
                    component={"button"}
                    onClick={() => handleOpenChange(false)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 3L21 21"
                        stroke="currentColor"
                        strokeWidth="2"
                      />
                      <path
                        d="M3 21L21 3"
                        stroke="currentColor"
                        strokeWidth="2"
                      />
                    </svg>
                  </IconButton>
                </div>
              )}
            </div>
            <div
              data-testid={"modal__content"}
              className={classNames("relative max-h-[75vh]", contentClassNames)}
            >
              {children}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
