import {
  DATO_ENV,
  DATO_PROXY_API,
  DATOCMS_BASE,
  DATOCMS_READ_ONLY_API_TOKEN,
  DATOCMS_READ_ONLY_API_TOKEN_HOMEPAGE,
  DATOCMS_READ_ONLY_API_TOKEN_REPORTS,
  DATOCMS_READ_ONLY_API_TOKEN_CONTENT,
  DATOCMS_READ_ONLY_API_TOKEN_RANKINGS,
  DATOCMS_READ_ONLY_API_TOKEN_MEMBERS,
  DATOCMS_READ_ONLY_API_TOKEN_EVENTS,
  DATOCMS_READ_ONLY_API_TOKEN_SITEWIDE_OPTIONS,
  DATOCMS_READ_ONLY_API_TOKEN_BRIEFINGS,
  DATOCMS_READ_ONLY_API_TOKEN_COMPANY,
} from "lib/constants";
import axios from "axios";
import { datadogLogger } from "lib/datadog/logger";

const API_URL = DATOCMS_BASE;

const apiTokens = {
  default: DATOCMS_READ_ONLY_API_TOKEN,
  homepage: DATOCMS_READ_ONLY_API_TOKEN_HOMEPAGE,
  briefings: DATOCMS_READ_ONLY_API_TOKEN_BRIEFINGS,
  events: DATOCMS_READ_ONLY_API_TOKEN_EVENTS,
  reports: DATOCMS_READ_ONLY_API_TOKEN_REPORTS,
  leaderboards: DATOCMS_READ_ONLY_API_TOKEN_RANKINGS,
  scout: DATOCMS_READ_ONLY_API_TOKEN_RANKINGS,
  rankings: DATOCMS_READ_ONLY_API_TOKEN_RANKINGS,
  sitewideOption: DATOCMS_READ_ONLY_API_TOKEN_SITEWIDE_OPTIONS,
  members: DATOCMS_READ_ONLY_API_TOKEN_MEMBERS,
  content: DATOCMS_READ_ONLY_API_TOKEN_CONTENT,
  company: DATOCMS_READ_ONLY_API_TOKEN_COMPANY,
};

type DatoOptions = {
  environment?: string;
  urlPath?: string;
};

const getToken = (path: string | undefined) => {
  if (!path) return apiTokens.default;

  const value = Object.entries(apiTokens).find(([key]) =>
    path.replaceAll("/", "").startsWith(key)
  );

  if (
    value &&
    value[1] &&
    typeof value[1] === "string" &&
    value[1] !== "undefined"
  ) {
    return value[1];
  } else {
    return apiTokens.default;
  }
};

const fetchFromDatoCms = async (
  query: string,
  preview?: boolean,
  { variables }: { variables?: Record<string, any> } = {},
  options?: DatoOptions
) => {
  const res = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken(options?.urlPath)}`,
      ...(preview && { "X-Include-Drafts": "true" }),
      ...(options?.environment && { "x-environment": options.environment }),
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    datadogLogger.error(json.errors);
    throw new Error(
      "Failed to fetch from DatoCMS API - please recheck your query"
    );
  }

  return json.data;
};

type FetchProxyOptions = {
  query: string;
  preview?: boolean;
  variables?: Record<string, any>;
  urlPath?: string;
};

export async function fetchFromDatoProxy<ReturnValue extends any = any>({
  query,
  preview,
  variables,
  urlPath,
}: FetchProxyOptions) {
  if (DATO_ENV && DATO_ENV !== "production") {
    const env = DATO_ENV !== "localhost" ? DATO_ENV : undefined;

    return await fetchFromDatoCms(query, preview, variables, {
      environment: env,
      urlPath: urlPath,
    });
  } else {
    return await axios
      .post<ReturnValue>(
        `${DATO_PROXY_API}${urlPath || ""}`,
        {
          query: query,
        },
        // content-api avoids cashing for preview parameter https://github.com/siftedeu/content-api/blob/main/src/routes/dato.js#L71
        { params: { preview } }
      )
      .then((res) => res.data)
      .catch<ReturnValue | null>((error) => {
        datadogLogger.error(error);
        return null;
      });
  }
}

type FetchProxyOptionsClientOptions = FetchProxyOptions;

export async function fetchFromDatoProxyClientSide<
  ReturnValue extends any = any,
>({ query, preview, urlPath }: FetchProxyOptionsClientOptions) {
  return await axios
    .post<ReturnValue>(
      `/api/dato`,
      {
        query: query,
      },
      { params: { preview, urlPath } }
    )
    .then((res) => res.data)
    .catch<ReturnValue | null>((error) => {
      datadogLogger.error(error);
      return null;
    });
}

export default fetchFromDatoCms;
