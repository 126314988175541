import type {
  SitewideOptionsNavbar,
  SitewideOptions,
  SitewideOptionsEditorsPick,
} from "lib/types/sitewideOption";
import { fetchFromDatoProxy, fetchFromDatoProxyClientSide } from "lib/cms/dato";
import { PaywallWindow } from "lib/types/paywalls";
import { Banner } from "lib/types/banners";

type NavbarResult = SitewideOptionsNavbar | null;

export const fetchNavbarAndFooter = async (): Promise<NavbarResult> => {
  const data = await fetchFromDatoProxy<{ sitewideOption: NavbarResult }>({
    query: `
    query Query {
      sitewideOption {
        footerCopyright
        footerLogoLabel
        footerExtraList {
          id
          label
          url
          description
        }
        footerPrimaryList {
          id
          label
          url
          description
        }
        footerSecondaryList {
          id
          label
          url
          description
        }
        footerCommunityList {
          id
          label
          url
          description
        }
        footerExtraListColumnLabel
        footerPrimaryListColumnLabel
        footerSecondaryListColumnLabel
        footerCommunityListColumnLabel
        footerSocials {
          id
          image {
            alt
            id
            height
            title
            width
            url
            blurUpThumb
          }
          label
          url
        }
        logInUrl
        logInLabel
        logoutLabel
        navbarPrimaryLinks {
          description
          id
          label
          url
          list {
            id
            label
            url
            description
          }
        }
        searchLabel
        searchUrl
        sidebarHeading
        sidebarPrimaryLinks {
          id
          label
          list {
            description
            id
            label
            url
          }
          url
          description
        }
        subscribeCtaLabel
        subscribeCtaLabelMobile
        subscribeCtaUrl
        userProfileLabel
        userProfileUrl
      }
    }
  `,
    urlPath: "/sitewideOptions/menus",
  });

  if (!data) {
    return null;
  }

  return data.sitewideOption;
};

type FetchCalloutsResponse = {
  sitewideOption: SitewideOptions["callouts"];
};

export const fetchCallouts = async (): Promise<Pick<
  SitewideOptions,
  "callouts"
> | null> => {
  const data = await fetchFromDatoProxy<FetchCalloutsResponse | null>({
    query: `
    query MyQuery {
  sitewideOption {
    calloutsNewsletterLoginCtaLabel
    calloutsNewsletterSignupCtaLabel
    calloutsBriefingsSubscribeCtaLabel
    calloutsBriefingsDiscoverCtaLabel
    calloutsNewsletterAlreadySubscribedCtaLabel
    calloutCopyList {
      id
      description
      createdAt
      calloutType

      backgroundColour {
        hex
      }
      authorsLabel
      accentButtonType
      primaryColour {
        hex
      }
      tagLabel
      tildaAccentColour {
        hex
      }
      timeLabel
      title
    }
  }
}
  `,
    urlPath: "/sitewideOptions/callouts",
  });

  if (!data) {
    return null;
  }

  return {
    callouts: {
      ...data.sitewideOption,
    },
  };
};

type FetchEditorsPickResponse = {
  sitewideOption: SitewideOptions["editorsPickArticles"] &
    SitewideOptions["editorsPickHeading"];
};

export const fetchEditorsPick =
  async (): Promise<SitewideOptionsEditorsPick | null> => {
    const data = await fetchFromDatoProxy<FetchEditorsPickResponse | null>({
      query: `
    query MyQuery {
      sitewideOption {
        editorsPickHeading
        editorsPickArticles {
          id
          title
          articleSlug
        }
      }
    }
  `,
      urlPath: "/sitewideOptions/editorsPick",
    });

    if (!data) return null;

    return { ...data.sitewideOption };
  };

type FetchNewslettersResponse = {
  sitewideOption: SitewideOptions["newsletters"];
};

export const fetchNewsletters = async (): Promise<Pick<
  SitewideOptions,
  "newsletters"
> | null> => {
  const data = await fetchFromDatoProxy<FetchNewslettersResponse | null>({
    query: `
    query MyQuery {
      sitewideOption {
        newsletters {
          id
          newsletterType
          title
          description
          frequency
          accentColour {
            hex
          }
          titleColour {
            hex
          }
          cardImage {
            id
            height
            width
            blurUpThumb
            alt
            url
          }
        }
      }
    }
  `,
    urlPath: "/sitewideOptions/newsletters",
  });

  if (!data || !data.sitewideOption) {
    return null;
  }

  const { newsletters } = data.sitewideOption;

  const newsMap = newsletters.reduce((map, obj) => {
    map[obj.newsletterType] = obj;
    return map;
  }, {});

  return {
    newsletters: newsMap,
  };
};

type FetchPaywallByIdResponse = {
  paywallWindow: PaywallWindow;
};

type FetchPaywallByIdParameters = {
  id: string;
  preview: boolean;
};
export const fetchPaywallById = async ({
  id,
  preview,
}: FetchPaywallByIdParameters): Promise<PaywallWindow | null> => {
  const data =
    await fetchFromDatoProxyClientSide<FetchPaywallByIdResponse | null>({
      query: `
      query PaywallWindowQuery {
        paywallWindow(filter: {id: {eq: "${id}"}}) {
          id
          name
          additionalButtonLabel
          additionalButtonUrl
          dismissButtonUrl
          dismissButtonLabel
          loginLabel
          primaryButtonLabel
          primaryButtonUrl
          subtitle
          theme
          title
          titleColour
        }
      }
  `,
      urlPath: `/paywall/${id}`,
      preview,
    });

  return data?.paywallWindow || null;
};

type FetchBannerById = {
  id: string;
  preview: boolean;
};

type FetchBannerByIdResponse = {
  banner: Banner;
};

export const fetchBannerById = async ({
  id,
  preview,
}: FetchBannerById): Promise<Banner | null> => {
  const data =
    await fetchFromDatoProxyClientSide<FetchBannerByIdResponse | null>({
      query: `
      query PaywallWindowQuery {
        banner(filter: {id: {eq: "${id}"}}) {
          id
          name
          primaryButtonLabel
          primaryButtonUrl
          heading
          text
          theme
          mainImage {
            alt
            id
            height
            title
            width
            url
            blurUpThumb
          }
        }
      }
  `,
      urlPath: `/banner/${id}`,
      preview,
    });

  return data?.banner || null;
};
